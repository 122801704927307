import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`While adding new fields to my Django models, running the command `}<inlineCode parentName="p">{`manage.py makemigrations`}</inlineCode>{` didn’t create any migrations and I couldn’t do anything because the database table was missing those columns.`}</p>
    <p>{`On `}<a parentName="p" {...{
        "href": "https://thumbsupnews.net"
      }}>{`ThumbsUpNews`}</a>{` the whole project is being served through docker-compose and running `}<inlineCode parentName="p">{`docker-compose exec db psql`}</inlineCode>{` didn’t work since I didn’t have my username on the database. `}</p>
    <p>{`After spending a few minutes searching, I’ve discovered that you could run the `}<inlineCode parentName="p">{`sql`}</inlineCode>{` command with the `}<inlineCode parentName="p">{`—username`}</inlineCode>{` flag to use a different user.`}</p>
    <p>{` `}<inlineCode parentName="p">{`docker-compose exec db psql --username=<youruser>`}</inlineCode></p>
    <p>{`Inside the PostgreSQL interactive terminal, I figured that I could just add the columns that were missing on the table. But it seems that I couldn’t find that table.`}</p>
    <p>{`Using the command `}<inlineCode parentName="p">{`\\l`}</inlineCode>{` allows us to list all databases available and I’ve noticed that I had a table named postgres which is the name of my docker container for the database.`}</p>
    <p>{`Then we have to run the command `}<inlineCode parentName="p">{`\\c postgres`}</inlineCode>{` to connect to that database, then we can run the command `}<inlineCode parentName="p">{`\\d <table name>`}</inlineCode>{` to see the existing columns of that table.`}</p>
    <p>{`Then all I had to do was to add new columns to the database.  `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sql"
      }}>{`ALTER TABLE <name of table>
ADD COLUMN <name of colum> <colum type>;
`}</code></pre>
    <p>{`We have to tell Postgres that we want to alter the table x and what kind of change we want to do, in this case, add a column. So let’s say that you want to add the column named  `}<em parentName="p">{`description`}</em>{` and for type, we just want a text type with unlimited length.`}</p>
    <p>{`You can do that by running the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sql"
      }}>{`ALTER TABLE <name of table>
ADD COLUMN description text;
`}</code></pre>
    <p>{`Note that you need to finish the ADD COLUMN line with a `}<inlineCode parentName="p">{`;`}</inlineCode></p>
    <p>{`That’s all there is to add columns to a Postgres database table.`}</p>
    <p><em parentName="p">{`Reference`}</em></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.postgresql.org/docs/9.5/datatype.html"
        }}>{`PostgreSQL: Documentation: 9.5: Data Types`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      